import '@/util/validation-rules';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuetify from 'vuetify/lib';
import Toast from 'vue-toastification';
import { PluginOptions } from 'vue-toastification/dist/types/src/types/index.d';
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate';
import nl from 'vee-validate/dist/locale/nl.json';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import '@fortawesome/fontawesome-free/css/all.css';
import '@/scss/main.scss';
import 'vue-toastification/dist/index.css';
import Echo from "laravel-echo";

import UnauthLayout from './components/layouts/UnauthLayout.vue';
import DefaultLayout from './components/layouts/DefaultLayout.vue';

Vue.use(VueAxios, axios);

// window.Pusher = require('pusher-js');
// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.VUE_APP_PUSHER_APP_KEY,
//   wsHost: 'localhost',
//   wsPort: 6001,
//   forceTLS: false,
//   disableStats: true,
//   cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
// });

Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  transitionDuration: 250,
  position: 'bottom-center',
  hideProgressBar: true,
  maxToasts: 5,
} as PluginOptions);

localize('nl', nl);

Vue.config.productionTip = false;

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('VUnauthLayout', UnauthLayout);
Vue.component('VDefaultLayout', DefaultLayout);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
