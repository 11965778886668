import axios from '@/util/axios';
import store from '@/store';
import { Paginated } from './interfaces';

export default {
    async addCertificate(body: Object) {
        const userId = (store.state as any).user.user.id as number;
        return (await axios.post(`/user/${userId}/certificate`, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }));
    },
    async getCertificates() {
        const userId = (store.state as any).user.user.id as number;
        return (await axios.get(`/user/${userId}/certificate`));
    },
    async deleteCertificate(certificate: number) {
        const userId = (store.state as any).user.user.id as number;
        return (await axios.delete(`/user/${userId}/certificate/${certificate}`));
    },
};
