
import Vue, { PropType } from 'vue';
import { Lesson } from '@/api/interfaces';

export default Vue.extend({
  name: 'MessageCard',
  props: {
    lesson: Object as PropType<Lesson>,
    icon: String,
    iconColor: String,
    points: String,
    withButton: Boolean,
    buttonText: String,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    startLesson() {
      this.$emit('startLesson', this.lesson.id);
    },
  },
});
