import axios from '@/util/axios';
import store from '@/store';
import { Paginated } from './interfaces';

export default {
    async sendCompanyChat(body: Object, header: any) {
        return header ? (await axios.post(`/company/chat`, body,{
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })) : (await axios.post(`/company/chat`, body,));
    },
    async getCompanyChats(body: Object) {
        return (await axios.post(`/company/chats`, body)).data;
    },
    async sendUserChat(body: Object, header: any) {
        return header ? (await axios.post(`/user/chat`, body,{
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })).data : (await axios.post(`/user/chat`, body,)).data;
    },
    async getUserChat() {
        return (await axios.get(`/user/chat`)).data;
    },
    async getUserChats(body: Object) {
        return (await axios.post(`/user/chats`, body));
    },
    async createGroup(body: Object) {
        return (await axios.post(`/user/group`, body));
    },
    async getGroups() {
        return (await axios.get(`/user/group`)).data;
    },
    async getGroupChats(id: number) {
        return (await axios.get(`/user/group/${id}`));
    },
    async sendGroupChat(id: number, body: Object, header: any) {
        return header ? (await axios.post(`/user/group/${id}`, body,{
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })).data : (await axios.post(`/user/group/${id}`, body)).data;
    },
    async addUserToGroup(user: number, group: number) {
        return (await axios.put(`/user/${user}/group/${group}`));
    },
};
