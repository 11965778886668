import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.students)?_c(VCard,{staticClass:"absolute rounded-lg",attrs:{"min-width":"200px","max-width":"400px","height":"100%"}},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":_vm.per_page,"hide-default-footer":""},on:{"click:row":_vm.execute},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"text-center pt-4 pb-2"},[_c(VRow,[_c(VCol,[_c(VPagination,{attrs:{"length":_vm.total},on:{"input":_vm.updatePagination},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }