/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios';
import axios from '@/util/axios';

export interface Profile {
  id: number,
  first_name: string,
  last_name: string,
  gender: string,
  birthdate: string,
  website: string,
  linkedin: string,
  institute: string,
  department: string,
  phone: string,
  mobile: string,
  address: string,
  postal_code: string,
  city: string,
  country: string,
  user_id?: number,
  slack_id: string,
  teams_id: string,
  discord_id: string,
  zoom_id: string,
  avatarUrl: string,
  media: [],
}

export interface Role {
  id: number,
  name: string,
  guard_name: string,
}

export interface UserSession {
  access_token: string;
  id: number;
  email: string,
  profile: Profile,
  roles: Role[],
}

export interface UserRegistration {
  first_name: string,
  last_name: string,
  password: string,
  email: string,
  birthdate: string,
}

export default {
  login(email: string, password: string) {
    return axios.post<{ email: string; password: string }, AxiosResponse<UserSession>>('/login', {
      email,
      password,
    });
  },
  logout() {
    // TODO - This route should be implemented in the backend
    // return axios.get<void>('/logout');
  },
  register(accountDetails: UserRegistration) {
    return axios.post<UserRegistration, AxiosResponse<UserSession>>('/user', accountDetails);
  },
  invite(token: string, password: string) {
    return axios.post<{token: string, password: string}, AxiosResponse<UserSession>>('/invite', {token, password});
  },
  async getCurrentUser(token?: string): Promise<UserSession> {
    return (await axios.get<UserSession>('/currentuser', { headers: { Authorization: `Bearer ${token}` } })).data;
  },
  /**
   * Retrieves the session cookie info for the authenticated user
   * @returns The currently logged in user's session info
   */
  async getSession(): Promise<UserSession> {
    return (await axios.get<UserSession>('/currentuser')).data;
  },
  async getOAuthRedirect() {
    return (await axios.get('/oauth/microsoft/redirect')).data;
  },
  async getTestResults() {
    return (await axios.get('/user/result')).data;
  },
  async updateUserProfile(profileId: number, profile: Partial<Profile>): Promise<AxiosResponse> {
    return axios.post<Partial<Profile>>(`/profile/${profileId}`, profile);
  },
  async requestPasswordReset(email: string): Promise<AxiosResponse> {
    return axios.post('/password/forgot-password', { email });
  },
  async resetPassword(token: string, password: string): Promise<AxiosResponse> {
    return axios.post('/password/reset', { token, password, password_confirmation: password });
  },
  async students(id: number, page: number){
    return page != 0? (await axios.get(`/user/role/${id}?page=${page}`)).data : (await axios.get(`/user/role/${id}`)).data;
  },
};
