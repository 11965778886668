
    import Vue from 'vue';
    import UserApi from '@/api/user';
    import moment from 'moment';
    import { Line as LineGraph } from 'vue-chartjs';
    import { Bar as BarGraph } from 'vue-chartjs';
    import {
        Chart as ChartJS,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    } from 'chart.js';

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    )

    export default Vue.extend({
        name: 'Progression',
        components: { LineGraph, BarGraph },
        data: () => ({
            testResults: undefined as any,
            data: {
                labels: [] as any,
                datasets: [
                    {
                        label: 'Leerlijn',
                        backgroundColor: '#0099ff',
                        data: []
                    }
                ] as any,
            }as any,
            dataBarGraph: {
                labels: ["Verdeling van cijfers"],
                datasets: [
                    {
                        label: "< 5.4",
                        backgroundColor: '#880808',
                        data: []
                    },
                    {
                        label: "5.5 - 6.9",
                        backgroundColor: '#FFA500',
                        data: []
                    },
                    {
                        label: "7.0 >",
                        backgroundColor: '#008000',
                        data: []
                    },
                ] as any,
            }as any,
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            optionsBarGraph: {
                responsive: true,
                maintainAspectRatio: false,
                maxBarThickness: 30,
            },
        }),
        mounted() {
            UserApi.getTestResults().then((res: any) => {
                this.testResults = res;
                this.setChart();
            });
        },
        methods: {
            setChart(){
                this.data.datasets[0].data = [];
                this.data.labels = [];
                var failGrade = 0;
                var passGrade = 0;
                var goodGrade = 0;

                this.testResults.forEach((result: any) => {
                    if(result.grade){
                        this.data.labels.push(moment(result.created_at).format('DD-MM-YYYY,'));
                        this.data.datasets[0].data.push(result.grade);
                        if(result.grade < 5.5)
                        {
                            failGrade++;
                        }
                        if(result.grade >= 5.5 && result.grade < 7.0)
                        {
                            passGrade++;
                        }
                        if(result.grade >= 7.0)
                        {
                            goodGrade++;
                        }
                    }
                    this.dataBarGraph.datasets[0].data[0] = failGrade;
                    this.dataBarGraph.datasets[1].data[0] = passGrade;
                    this.dataBarGraph.datasets[2].data[0] = goodGrade;
                });
            }
        },
    });
