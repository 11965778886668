
import Vue from 'vue';

export default Vue.extend({
  name: 'FileCard',
  props: {
    students: Object,
  },
  data(){
    return {
        headers: [{ text: 'Email', value: 'email' } ],
        currentPage: this.students.current_page,
        data: this.students.data,
        per_page: this.students.per_page,
        total: this.students.total,
        selected: [],
    }
  },
  methods: {
    share(){ return this.$emit('share', this.selected.map( (user: any) => user.id ));  },
    updatePagination(value: number){ return this.$emit('updatePagination', value); }
  },
});
