import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VSheet,[_c(VDialog,{staticClass:"overflow-hidden",attrs:{"min-width":"200px","max-width":"400px","height":"100%"},model:{value:(_vm.filecard_dialog),callback:function ($$v) {_vm.filecard_dialog=$$v},expression:"filecard_dialog"}},[(_vm.students)?_c('FileCard',{staticClass:"overflow-hidden",attrs:{"students":_vm.students},on:{"updatePagination":_vm.getStudent,"share":_vm.share}}):_vm._e()],1),_c(VRow,{staticClass:"mb-2"},[_c(VCol,[_c('h1',{staticClass:"font--body primary--text"},[_vm._v("Mijn bestanden")]),_c('h3',{staticClass:"font--body"},[_vm._v("Hier kunt u uw bestanden in kijken")])])],1),_c(VRow,[_c(VCol,[_c('v-table',{attrs:{"fixed-header":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Bestand")]),_c('th',{staticClass:"text-left"}),_c('th',{staticClass:"text-left"},[_vm._v("Opties")])])]),_c('tbody',_vm._l((_vm.media),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_c(VBtn,{staticClass:"rounded-lg ml-4 mr-16",attrs:{"text":"","href":item.url,"color":"primary"}},[_vm._v("Bekijk bestand")])],1),_c('td',[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.setTrackedMedia(item)}}},[_c(VIcon,{attrs:{"size":"small"}},[_vm._v("fa-share ")])],1),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.deleteFile(item.id)}}},[_c(VIcon,{attrs:{"size":"small"}},[_vm._v("fa-trash")])],1)],1)])}),0)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }