
  import Vue from 'vue';
  import { mapMutations, mapState } from 'vuex';
  import { AxiosError } from 'axios';
  import store from '@/store';
  import PortfolioAPI from '@/api/portfolio';

  
  export default Vue.extend({
    name: 'Portfolio',

    data() {
      return {
        portfolio: undefined as any,
      };
    },
    mounted() {
        this.setIsLoadingOverlay(true);
        let id = atob(this.$route.query.portfolio.toString());
    
        PortfolioAPI.getPortfolio(parseInt(id)).then((res: any)=>{
            this.portfolio = res.data;
            this.setIsLoadingOverlay(false);
        });
    },
    computed: {
      ...mapState('app', ['isLoadingOverlay']),
    },
    methods: {
      ...mapMutations('app', ['setIsLoadingOverlay']),
    }
  });
