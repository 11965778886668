import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VSheet,[_c(VRow,{staticClass:"mb-2"},[_c(VCol,[_c('h1',{staticClass:"font--body primary--text"},[_vm._v("Uw progressie")]),_c('h3',{staticClass:"font--body"},[_vm._v("Bekijk hier uw voortgang & leerlijn")])])],1),_c(VRow,{staticClass:"mb-2"},[_c(VCol,{staticClass:"pl-8 pr-8"},[(_vm.testResults)?_c(VCard,[_c('LineGraph',{attrs:{"data":_vm.data,"options":_vm.options}})],1):_vm._e()],1)],1),_c(VRow,{staticClass:"pl-5"},[_c(VCol,[(_vm.testResults)?_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v("Laatst gemaakt toest:")]),_c(VCol,{attrs:{"align":"left"}},[_c('b',[_vm._v("Toets:")]),_vm._v(" "+_vm._s(_vm.testResults[0].test.name)+" ")])],1):_vm._e()],1),_c(VCol,[(_vm.testResults)?_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v(" Resultaten:")]),_c(VCol,{attrs:{"align":"left"}},[_c('b',[_vm._v("Cijfer:")]),_vm._v(" "+_vm._s(_vm.testResults[0].grade)+" ")]),_c(VCol,{attrs:{"align":"left"}},[_c('b',[_vm._v("Opmerking:")]),_vm._v(" "+_vm._s(_vm.testResults[0].comment ? _vm.testResults[0].comment : '---')+" ")])],1):_vm._e()],1),_c(VCol,[(_vm.testResults)?_c(VCard,[_c('BarGraph',{attrs:{"data":_vm.dataBarGraph,"options":_vm.optionsBarGraph}})],1):_vm._e()],1),_c(VCol)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }